import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import dayjs from 'dayjs';

import SEO from "../components/seo";
import Layout from "../components/layout";

const Updates = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "content/(updates)/" } }
      ) {
        nodes {
          frontmatter {
            title
            bullets
          }
        }
      }
    }
  `);

  const sortDates = ( a, b ) => {
    const first = a.frontmatter.title
    const second = b.frontmatter.title

    if (dayjs(first).diff(second) > 0){
      return -1;
    }
    if (dayjs(first).diff(second) < 0){
      return 1;
    }
    return 0;
  }


  const res = data.allMarkdownRemark.nodes.reverse()
  res.sort(sortDates)

  return (
    <>
      <SEO title="Updates" />
      <Layout>
        <main className="max-w-container flex flex-col space-y-10 py-20 px-10 lg:px-0 mx-auto" >
          <h2 className="max-w-paragraph-heading">Release Notes</h2>
          {res.map((data) => (
            <div>
              <div className="font-bold text-xl mb-4">{data.frontmatter.title}</div>
              <ul className="list-disc ml-4">
              {
                data.frontmatter.bullets.map((bullet) => (
                  <li>{bullet}</li>
                ))
              }
              </ul>
             
            </div>
          ))}
        </main>
      </Layout>
    </>
  );
};

export default Updates;
